@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    font-family: 'Open Sans', sans-serif;
}
html, body {
  max-width: 100%;
  overflow-x: hidden;
  /* scroll-snap-type: y proximity; */
  scroll-behavior: smooth;

}
.image-container {
    position: relative;
    width: 200px; /* Adjust the width as needed */
    height: 200px; /* Adjust the height as needed */
    overflow: hidden;
  }
  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Maintain aspect ratio and cover container */
  }

.fade-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(15, 15, 16, 1) 70%, rgba(15, 15, 16, 1) 100%), url('./images/Logo.png');

}

.glow {
  box-shadow: 0 0 10px rgba(64, 70, 230, 0.7);
}

::selection {
  background-color: #dc8118;
  color: #0f0f10;
}

p::selection {
  background-color: #dc8118;
  color: #0f0f10;
}


/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 0px;
}

/* Thumb */


section{
  scroll-snap-align: start;
}
/* Shooting stars background */
/* ================================================================================================ */
.modal-open {
  overflow: hidden;
}



#mynav{
  /* transition-all duration-500 ease-in-out */
  transition: top 0.5s ease;
}